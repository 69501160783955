<script setup lang="ts">
import type { PropType } from 'vue'
import { ObjectId } from 'bson'
import ProductCard from '~/components/products/product-card.vue'
import { getProducts } from '~/shared/productListing'
import type { ProductType } from '~/models/product'
import { useAclStore } from '~/store/acl'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  categoryId: {
    type: Object as PropType<ObjectId>,
    required: true,
  },
  products: {
    type: Array as PropType<ProductType[]>,
    required: true,
  },
  class: {
    type: String,
    default: '',
  },
})
const loading = ref(false)
const $acl = useAclStore()
const productList = ref<ProductType[]>()

onMounted(async () => {
  // await getConfigs()
})

async function getConfigs() {
  productList.value = (await getProducts({
    filters: {
      categories:
      {
        $in: [
          props.categoryId,
        ],
      },
    },
    page: 1,
    limit: 6,
    sorts: [{ field: 'createdAt', dir: 'desc' }],
  })).list

  setTimeout(() => {
    loading.value = false
  }, 100)
}
</script>

<template>
  <div class="px-4 ">
    <div class="header">
      <span v-if="!loading" class="section">{{ label }}</span>
    </div>

    <div>
      <div class="flex flex-wrap w-full justify-center md:justify-left gap-2">
        <template v-for="(product, i) in products" :key="i">
          <a-skeleton v-if="loading" active />
          <ProductCard v-if="!loading" :product="product" />
        </template>
      </div>
    </div>
  </div>
  <slot />
</template>

<style scoped>
.header {
  margin-bottom: 20px;
}
.section {
  font-size: 30px;
  color: #30421c;
}
.routerLink {
  color: black;
}
</style>
