<script setup lang="ts">
import { ObjectId } from 'bson'
import Banner from '~/components/Banner.vue'
import NewsLetter from '~/components/NewsLetter.vue'
import Features from '~/components/Features.vue'
import ProductSection from '~/components/homepage/ProductSection.vue'
import ProductSectionwithImage from '~/components/homepage/ProductSectionWithImage.vue'

import type { ProductType } from '~/models/product'
import { ProductModel } from '~/models/product'

import { useAclStore } from '~/store/acl'
import { mapBoxLocation } from '~/shared/location'
import { dataFetchList } from '~/realm/data/list'
import { siteHomeCategoryId, siteRootCategoryId } from '~/shared/site'

const $acl = useAclStore()

definePageMeta({
  layout: 'home',
})

const serverLoaded = ref(false)
const loading = ref(true)
interface CategoryConfigType {
  categoryId: string
  products: ProductType[]
  label: string
  img: string
  uri: string
  imgPosition: string
}

const emptyCategoryConfig = {
  categoryId: '',
  products: [],
  label: '',
  img: '',
  uri: '',
  imgPosition: '',
}
const productModel = new ProductModel()
const categories = ref<CategoryConfigType[]>([])
const hotPick = ref<CategoryConfigType>()

const fetchProductByCategories = async (
  label: string,
  categoryId: string,
  withRootCategory = true,
  uri: string,
  img = '',
  imgPosition = 'left',
  limit = 20,
) => {
  // console.log('loading category', categoryId, imgPosition)
  if (!categoryId) {
    console.error('category loading error', categoryId)
    return emptyCategoryConfig
  }

  // fetch category + those tagged to root category
  const res = await dataFetchList(productModel, {
    page: 1,
    limit,
    filters: {
      $and: [
        {
          status: 1,
        },
        {
          categories: {
            $in: [new ObjectId(categoryId)],
          },
        },
        ...(withRootCategory
          ? [
              {
                categories: siteHomeCategoryId,
              },
            ]
          : []),
      ],
    },
    sorts: [
      { field: 'weight', dir: 'asc' },
      { field: '_id', dir: 'desc' },
    ],
  })

  return { label, categoryId, products: res.list, img, uri, imgPosition }
}

const loadCategories = async () => {
  loading.value = true
  categories.value = [
    emptyCategoryConfig,
    emptyCategoryConfig,
  ]

  await Promise.all([
    fetchProductByCategories(
      'Hot Pick',
      '621c71c60136bc9226934a66',
      false,
      '/c/sales',
    ).then((res) => {
      hotPick.value = res
    }),
    fetchProductByCategories(
      'Skin care',
      '62846d94015b3db3369efced',
      true,
      '/c/skincare',
      '/img/banner_high_skincare.png',
    ).then((res) => {
      categories.value[0] = res
    }),
    fetchProductByCategories(
      'Therapies',
      '62847e75015b3db336a0f38f',
      true,
      '/c/therapies',
      '/img/banner_high_therapies.png',
      'right',
    ).then((res) => {
      categories.value[1] = res
    }),
    // fetchProductByCategories(
    //   'Spa & Wellness',
    //   '621c74050136bc9226938590',
    //   true,
    //   '/c/spaWelness',
    //   '/img/banner_high_spawellness.png',
    // ).then((res) => {
    //   categories.value[2] = res
    // }),
  ])
  loading.value = false
}

onServerPrefetch(async () => {
  console.log('onServerPrefetch')
  // component is rendered as part of the initial request
  // pre-fetch data on server as it is faster than on the client
  //
  // only load on production npm run build, npm run preview
  await loadCategories()
  serverLoaded.value = true
})

onMounted(async () => {
  await Promise.all([mapBoxLocation($acl), loadCategories()])
  console.log('onMounted', categories.value)
})

const $nav = useNavStore()
$nav.setTitle('Home')

defineRouteRules({
  prerender: true,
});
</script>

<template>
  <div>
    <Banner />
    <div class="content pt-4">
      <a-spin :spinning="!serverLoaded && loading" class="w-full py-20">
        <ProductSection
          v-if="serverLoaded || (!loading && hotPick)"
          class="mt-2"
          label="Hot Pick"
          :category-id="new ObjectId(hotPick!.categoryId)"
          :products="hotPick!.products"
        >
          <div class="h-10"></div>
        </ProductSection>

        <div v-if="serverLoaded || !loading">
          <ProductSectionwithImage
            v-for="cat in categories"
            :key="cat.uri"
            :uri="cat.uri"
            :img="cat.img"
            :img-position="cat.imgPosition"
            :label="cat.label"
            :category-id="new ObjectId(cat.categoryId)"
            :products="cat.products"
          >
            <div class="h-10"></div>
          </ProductSectionwithImage>
        </div>
      </a-spin>
    </div>

    <div class="h-6"></div>
    <NewsLetter />
    <div style="width: 100%; height: 38px"></div>
    <Features />
  </div>
</template>

<style scoped>
.spacer {
  background-color: transparent;
  margin-bottom: 100px;
  width: 100%;
  height: 38px;
}
</style>
