<script setup lang="ts">
import { onMounted, ref } from 'vue'
import type { BannerType } from '~/models/banner'
import { BannerModel } from '~/models/banner'
import { dataFetchList } from '~/realm/data/list'
const bannerList = ref<BannerType[]>()
const loading = ref(true)

onMounted(async () => {
  await getBanners()
})

async function getBanners() {
  bannerList.value = (
    await dataFetchList(new BannerModel(), {
      filters: { section: 'home' }, // Filter list of addresses to match only to its Owner
      page: 1,
      limit: 9999,
      sorts: [],
    })
  ).list
  setTimeout(() => {
    loading.value = false
  }, 1000)
}
</script>

<template>
  <a-carousel v-if="!loading" autoplay class="w-full mt-2">
    <template v-for="(banner, i) in bannerList" :key="banner._id.toString()">
      <template v-for="(image, j) in banner.photoURLs" :key="image.original">
        <NuxtLink :to="banner.link"  rel="noopener noreferrer" v-if="banner.link">
        <img :srcset="imageSrcset(image)" loading="lazy" class="" />
        </NuxtLink>
        <img :srcset="imageSrcset(image)" loading="lazy" class="" v-else />
      </template>
    </template>
  </a-carousel>

  <div v-else>
    <div class="loading">
      <a-spin tip="Loading Banner..." />
    </div>
  </div>
</template>

<style scoped>
/* For demo */
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  /* height: 600px; */
  line-height: 200px;
  background: rgba(0, 0, 0, 0.2);
  /* background: #364d79; */
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
.loading {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 300px 300px;
  margin: 20px 0;
  height: 600px;
}
</style>
