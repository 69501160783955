<script setup lang="ts">
import type { PropType } from 'vue'
import { ObjectId } from 'bson'
import ProductCard from '~/components/products/product-card.vue'
import type { ProductType } from '~/models/product'
import { useAclStore } from '~/store/acl'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  categoryId: {
    type: Object as PropType<ObjectId>,
    required: true,
  },
  products: {
    type: Array as PropType<ProductType[]>,
    required: true,
  },
  img: {
    type: String,
    required: true,
  },
  uri: {
    type: String,
    required: true,
  },
  imgPosition: {
    type: String,
    default: 'left',
  },
})
const loading = ref(false)
const $acl = useAclStore()

onMounted(async () => {
  // await getConfigs()
})
</script>

<template>
  <div>
    <div class="header">
      <a-row>
        <a-col :xs="24" :sm="24" :md="12" :lg="12">
          <span v-if="!loading" class="section">Shop {{ label }}</span>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12" :lg="12">
          <span style="float: right">
            <router-link v-if="!loading" :to="uri" class="routerLink">
              Shop All {{ label }}
            </router-link>
          </span>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-row :gutter="[8, 16]">
        <a-col v-if="imgPosition === 'left'" :xs="24" :sm="24" :md="10" :lg="10">
          <div>
            <img v-lazy="{ src: img }" class="w-full" style="height: 680px; object-fit: cover" />
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="14" :lg="14">
          <a-row :gutter="[16, 8]" class="flex justify-start <md:justify-center">
            <template v-for="(product, j) in products" :key="j">
              <a-col class="wrap" :xs="12" :sm="10" :md="10" :lg="8" :xl="8">
                <ProductCard :product="product" />
              </a-col>
            </template>
          </a-row>
        </a-col>
        <a-col v-if="imgPosition === 'right'" :xs="24" :sm="24" :md="10" :lg="10">
          <div>
            <img v-lazy="{ src: img }" class="w-full" style="height: 680px; object-fit: cover" />
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
  <slot />
</template>

<style scoped>
.header {
  margin-bottom: 20px;
}

.section {
  font-size: 30px;
  color: #30421c;
}

.routerLink {
  color: black;
}
</style>
