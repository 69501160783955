import BaseModel from './base'
import type { _BaseType } from './_base_type'
import type { PhotoURL } from './photo_url'

export type BannerType = _BaseType & {
  name: string

  photos: string[]
  photoURLs?: Array<PhotoURL>
  link?: string

  status: number
}

export class BannerModel extends BaseModel {
  constructor() {
    super('banners')

    this.uniques = [['name']]

    this.required = ['name', 'status']

    this.fields = {
      name: { type: 'string' },
      photos: { type: 'string[]' },
    }
  }
}
