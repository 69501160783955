<script lang="ts" setup>
import {
  Breadcrumb as ABreadcrumb,
  Button as AButton,
  Col as ACol,
  Form as AForm,
  Input as AInput,
  InputNumber as AInputNumber,
  Radio as ARadio,
  Row as ARow,
  Select as ASelect,
  Spin as ASpin,
  Switch as ASwitch,
} from 'ant-design-vue'

import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { dataFetchSingle } from '~/realm/data/get'
import type { SaveDataType } from '~/realm/data/save'
import { dataSaveSingle } from '~/realm/data/save'
import { dataFetchList } from '~/realm/data/list'
import type { NewsLetterType } from '~/models/news_letter'
import { NewsLetterModel } from '~/models/news_letter'
import { useAclStore } from '~/store/acl'
const AInputGroup = AInput.Group
const AFormItem = AForm.Item
const $store = useAclStore()

const newsLetterModel = new NewsLetterModel()
const newsLetterList = ref<NewsLetterModel[]>()

const $route = useRoute()
const $router = useRouter()
const newsLetter = ref<NewsLetterType | undefined>(undefined)
const loaded = ref(false)
const loading = ref(true)

const fetchData = async () => {
  const userId = $store.currentUser.uid as string
  newsLetterList.value = (
    await dataFetchList(newsLetterModel, {
      filters: { _ownerId: userId },
      page: 1,
      limit: 9999,
      sorts: [],
    })
  ).list

  try {
    const res = await dataFetchSingle<any>(newsLetterModel, 'new', {})
    console.log('fetchdata', res)
    newsLetter.value = res
  }
  catch (e: any) {
    console.error(e)
  }
}

onMounted(async () => {
  await fetchData()
  loaded.value = true
  loading.value = false
})

const handleSubmit = async () => {
  try {
    loading.value = true
    if (!newsLetter.value) {
      throw new Error('Email Address is Required')
    }

    const data = newsLetter.value as NewsLetterType
    // const isNew = !data._id
    const res = await dataSaveSingle<SaveDataType>(
      $store.currentUser,
      newsLetterModel,
      data,
    )
    if (res) {
      console.log('Saved')
    }
    setTimeout(() => {
      $router.push($route.fullPath)
    }, 500)
  }
  catch (e: any) {
    console.error('unknown error', e)
  }
  finally {
    loading.value = false
  }
}
</script>

<template>
  <div>
    <div class="flex h-[400px] w-full">
      <div style="background: #e9bf8c url('/img/banner_high_newsletter.png') no-repeat center 0"
        class="bg-contain bg-center h-full flex-1 p-4 hidden md:block">
      </div>
      <div class="flex-1 p-4 " style="background: rgb(203, 174, 160);">
        <div class="max-w-full p-10">
          <p style="color: whitesmoke; font-size: 30px; padding: 5px">
            Join our newsletter
          </p>
          <p style="color: whitesmoke; font-size: 20px; padding: 5px">
            get weekly access to our best deals, tips and tricks
          </p>
          <AForm v-if="loaded && newsLetter" layout="inline" method="post" class="frm" @submit.prevent="handleSubmit">
            <AFormItem>
              <AInput v-model:value="newsLetter.email" placeholder="Enter your e-mail here" />
            </AFormItem>

            <ARow type="flex">
              <ACol>
                <AButton v-if="!loading" type="ghost" class="button" @click="handleSubmit">JOIN
                </AButton>
                <AButton v-if="loading" type="primary" class="button">JOIN</AButton>
              </ACol>
            </ARow>
          </AForm>

          <p style="font-size: 15px; padding: 5px">
            No spam, we hate it more than you do.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.center {
  margin: auto;
  width: 70%;
  padding: 10px;
}

.button {
  background: #30421c;
  color: white;
  border-color: #536b37;
}

.button:hover {
  background: #485e30;
}
</style>
